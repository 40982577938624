import captionStyles from "../../styles/captions.module.css";
import styles from "../../styles/video-layout.module.css";

import { Captions, Controls, Gesture } from "@vidstack/react";

import * as Buttons from "../buttons";
import * as Sliders from "../sliders";
import { TimeGroup } from "../time-group";
import Select from "react-dropdown-select";
import { useEffect, useState } from "react";

interface NEXTPREVDETAILS {
  nextVideo: { title: string, filename: string, thumnail: string } | undefined;
  prevVideo: { title: string, filename: string, thumnail: string } | undefined;
}
export interface VideoLayoutProps {
  thumbnails?: string;
  nextprevvideDetails: NEXTPREVDETAILS
  playNextVideo: () => void;
  playPrevVideo: () => void;
}

export function VideoLayout({
  thumbnails,
  nextprevvideDetails,
  playNextVideo,
  playPrevVideo,
}: VideoLayoutProps) {
  const { nextVideo, prevVideo } = nextprevvideDetails

  const [selectedSpeed, setSelectedSpeed] = useState(1);
  const options = [
    { value: "0.5", label: "0.5x" },
    { value: "0.75", label: "0.75x" },
    { value: "1", label: "1x" },
    { value: "1.25", label: "1.25x" },
    { value: "1.5", label: "1.5x" },
    { value: "1.75", label: "1.75x" },
    { value: "2", label: "2x" },
  ];

  const handleSelectChange = (values: any) => {
    const speed = parseFloat(values[0].value);
    setSelectedSpeed(speed);
    const videoPlayer = document.querySelector('video');
    if (videoPlayer) {
      videoPlayer.playbackRate = speed;
    }
  };

  useEffect(() => {
    setSelectedSpeed(1);
    const videoPlayer = document.querySelector('video');
    if (videoPlayer) {
      videoPlayer.playbackRate = 1;
    }
  }, [nextprevvideDetails]);

  return (
    <>
      <Gestures />
      <Captions className={captionStyles.captions} />
      <Controls.Root className={styles.controls}>
        <div className={styles.spacer} />
        {thumbnails !== null && (
          <Controls.Group className={styles.controlsGroup}>
            <Sliders.Time thumbnails={thumbnails} />
          </Controls.Group>
        )}
        <Controls.Group className={styles.controlsGroup}>
          <Buttons.Play tooltipPlacement="top start" />
          {prevVideo && <Buttons.Prev
            tooltipPlacement="top start"
            thumnail={prevVideo.thumnail}
            fileName={prevVideo.filename}
            title={prevVideo.title}
            playPrevVideo={() => playPrevVideo()}
          />}
          {nextVideo && <Buttons.Next
            tooltipPlacement="top end"
            thumnail={nextVideo.thumnail}
            fileName={nextVideo.filename}
            title={nextVideo.title}
            playNextVideo={() => playNextVideo()}
          />}
          <Buttons.Backward tooltipPlacement="top start" />
          <Buttons.Forward10 tooltipPlacement="top start" />
          <Buttons.Mute tooltipPlacement="top" />
          <Sliders.Volume />
          <TimeGroup />
          <div className={styles.spacer} />
          <Select
            options={options}
            values={[
              {
                value: selectedSpeed.toString(),
                label: `${selectedSpeed}x`,
              },
            ]}

            onChange={handleSelectChange}
            style={{ marginBottom: "1px" }}
            dropdownPosition={"top"}
            searchable={false}
            closeOnClickInput={true}
            className="VideoSpeedSelect"
          />
          <Buttons.PIP tooltipPlacement="top" />
          <Buttons.Fullscreen tooltipPlacement="top end" />
        </Controls.Group>
      </Controls.Root>
    </>
  );
}

function Gestures() {
  return (
    <>
      <Gesture
        className={styles.gesture}
        event="pointerup"
        action="toggle:paused"
      />
      <Gesture
        className={styles.gesture}
        event="dblpointerup"
        action="toggle:fullscreen"
      />
      <Gesture
        className={styles.gesture}
        event="pointerup"
        action="toggle:controls"
      />
      <Gesture
        className={styles.gesture}
        event="dblpointerup"
        action="seek:-10"
      />
      <Gesture
        className={styles.gesture}
        event="dblpointerup"
        action="seek:10"
      />
    </>
  );
}
